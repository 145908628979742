<template>
	<userProfileLayout>
		<div class="user_profile">
			<div class="content-page">

				<!-- Skeleton Loader -->
				<div class="head_profile" v-if="loader === true">
					<div class="container">
						<headSkeletonLoader :parent="'user-profile'"></headSkeletonLoader>
					</div>
				</div>

				<div class="head_profile" v-if="loader === false">
					<div v-if="!isImageDefault" class="profile_head_background" v-bind:style="{'background-image': 'url('+(smallImageUrl)+')'}"></div>
					<div v-if="isImageDefault" class="profile_head_background" v-bind:style="{'background-image': 'url('+(smallImageUrl)+')'}"></div>

					<div class="container">
						<div class="user_photo">
							<v-img
                v-if="!isImageDefault"
								min-width="100%"
								min-height="100%"
								:src="`${smallImageUrl}?cache=${cacheKey}`"
                v-bind:srcset="`${smallImageUrl}?cache=${cacheKey} 1x, ${smallImageUrlSrcset}?cache=${cacheKey} 2x`"
								:class="{'default-image': isImageDefault}"
								@click="$refs['modal-image'].show()"
							></v-img>
              <v-img
                  v-if="isImageDefault"
                  min-width="100%"
                  min-height="100%"
                  :src="smallImageUrl"
                  :class="{'default-image': true}"
                  @click="$refs['modal-image'].show()"
              ></v-img>
						</div>
						<div class="user_info artist_info">
							<div class="mobile_artist_name" v-if="mobile">
								<h2 class="artist_name">
									{{ PROFILE_USER.fullName }}
								</h2>

								<!-- more button -->
								<v-menu
									transition="slide-y-transition"
									:close-on-content-click="true"
									offset-y
									nudge-left="21"
									nudge-bottom="10"
									origin="top center"
									content-class="menu-pointer charcoal">

									<template v-slot:activator="{ on, attrs }">
										<v-btn icon color="#7481A6" class="more" v-bind="attrs" v-on="on">
											<span class="material-icons">more_vert</span>
										</v-btn>
									</template>
									<v-list>
										<!-- popup share link -->
										<v-list-item>
											<v-dialog
												transition="dialog-bottom-transition"
												max-width="600">
												<template v-slot:activator="{ on, attrs }">
													<button type="button" v-ripple v-bind="attrs" v-on="on">Share</button>
												</template>
												<template v-slot:default="dialog">
													<div class="content_popup_share">
														<div class="head_popup">Share</div>
														<div class="input_box">
															<input type="text" id="input-share-link-user-profile" v-model="shareLink">
															<button type="button" v-ripple @click="copyUserLink">Copy</button>
														</div>
														<button type="button" v-ripple @click="dialog.value = false" class="close">Close</button>
													</div>
												</template>
											</v-dialog>
										</v-list-item>
										<!-- Report -->
										<v-list-item>
											<button type="button" v-ripple class="report">Report</button>
										</v-list-item>
									</v-list>
								</v-menu>
							</div>

							<h2 class="artist_name" v-if="!mobile">
								{{ PROFILE_USER.fullName }}

								<template v-if="PROFILE_USER.subscription && PROFILE_USER.subscription === 'premium'">
									<v-tooltip top close-delay="0" transition="false">
										<template v-slot:activator="{ on, attrs }">
											<div class="subscription_plan" v-bind="attrs" v-on="on"
											     :class="{'premium': PROFILE_USER.subscription === 'premium', 'founder': PROFILE_USER.isFounder === true}">
											</div>
										</template>
										<div v-if="PROFILE_USER.subscription === 'premium' && PROFILE_USER.isFounder === false">Premium account</div>
										<div v-if="PROFILE_USER.subscription === 'premium' && PROFILE_USER.isFounder === true">Founder's account</div>
									</v-tooltip>
								</template>
							</h2>

							<div class="artist_location" v-if="PROFILE_USER.address">
								{{ PROFILE_USER.address }}
							</div>

							<div class="follows">
								<div class="followers">
									<span class="count">{{ PROFILE_USER.followersCount }}</span>
									<div>Followers</div>
								</div>
								<div class="following">
									<span class="count">{{ PROFILE_USER.followingCount }}</span>
									<div>Following</div>
								</div>
							</div>

						</div>
					</div>
				</div>


				<div class="wrap_profile_music_events">
					<div class="head_profile_music_events">
						<v-container>
							<v-tabs
								background-color="transparent"
								color="#fff"
								v-model="tabs">
								<v-tab v-for="tab in tabsSection" :key="tab">
									{{ tab }}
								</v-tab>
							</v-tabs>
							<div class="actions">
								<template v-if="actionButtonsLoader === true || followBtnLoader || messageBtnLoader || supportBtnLoader">
									<v-skeleton-loader v-if="followBtnLoader || !this.AUTH_DATA.accessToken.length" max-width="300" height="34" type="button"></v-skeleton-loader>
									<v-skeleton-loader v-if="messageBtnLoader || !this.AUTH_DATA.accessToken.length" max-width="300" height="34" type="button"></v-skeleton-loader>
									<v-skeleton-loader v-if="supportBtnLoader || !this.AUTH_DATA.accessToken.length" max-width="300" height="34" type="button"></v-skeleton-loader>
								</template>
								<template v-if="actionButtonsLoader === false && this.AUTH_DATA.accessToken">
									<button type="button" class="follow_btn" v-if="this.isFollow === false && !followBtnLoader" @click="follow(PROFILE_USER.id)">
										<span class="icon"></span>
										Follow
									</button>
									<button type="button" class="follow_btn following" v-else-if="!followBtnLoader" @click="unfollow(PROFILE_USER.id)" @mouseover="hoverFollowingButton = true"
									        @mouseleave="hoverFollowingButton = false">
										<span class="icon"></span>
										<template v-if="hoverFollowingButton === true">Unfollow</template>
										<template v-else>Following</template>
									</button>
									<button type="button" class="message_btn" @click="showComingSoonDialog = true">
										<span class="icon"></span>
										Message
									</button>
                  <button v-if="!copiedUserLink" type="button" class="share_btn" @click="copyUserLink">
                    <span class="icon"></span>
                    Share
                  </button>
                  <button v-if="copiedUserLink" type="button" class="share_btn">
                    Copied!
                  </button>
								</template>

                <template v-if="actionButtonsLoader === false && !this.AUTH_DATA.accessToken">
                  <button type="button" class="follow_btn" @click="dialogNeedLoginVisible = true">
                    <span class="icon"></span>
                    Follow
                  </button>
                  <button type="button" class="message_btn" @click="dialogNeedLoginVisible = true">
                    <span class="icon"></span>
                    Message
                  </button>
                  <button v-if="!copiedUserLink" type="button" class="share_btn" @click="copyUserLink">
                    <span class="icon"></span>
                    Share
                  </button>
                  <button v-if="copiedUserLink" type="button" class="share_btn">
                    Copied!
                  </button>
                </template>

								<!-- more button -->
								<v-menu
									transition="slide-y-transition"
									:close-on-content-click="true"
									offset-y
									nudge-left="21"
									nudge-bottom="10"
									origin="top center"
									content-class="menu-pointer charcoal">
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon color="#7481A6" class="more" v-bind="attrs" v-on="on">
											<span class="material-icons">more_vert</span>
										</v-btn>
									</template>
									<v-list>
										<!-- popup share link -->
<!--										<v-list-item>-->
<!--											<v-dialog-->
<!--												transition="dialog-bottom-transition"-->
<!--												max-width="600">-->
<!--												<template v-slot:activator="{ on, attrs }">-->
<!--													<button type="button" v-ripple v-bind="attrs" v-on="on">Share</button>-->
<!--												</template>-->
<!--												<template v-slot:default="dialog">-->
<!--													<div class="content_popup_share">-->
<!--														<div class="head_popup">Share</div>-->
<!--														<div class="input_box">-->
<!--															<input type="text" id="input-share-link-user-profile" v-model="shareLink">-->
<!--															<button type="button" v-ripple @click="copyUserLink">Copy</button>-->
<!--														</div>-->
<!--														<button type="button" v-ripple @click="dialog.value = false" class="close">Close</button>-->
<!--													</div>-->
<!--												</template>-->
<!--											</v-dialog>-->
<!--										</v-list-item>-->
										<!-- Report -->
										<v-list-item>
											<button type="button" v-ripple class="report">Report</button>
										</v-list-item>
									</v-list>
								</v-menu>

							</div>
						</v-container>
					</div>
					<div class="content_profile_music_events">
						<v-tabs-items touchless v-model="tabs">
							<v-tab-item :transition="false">
								<div class="music_section">

									<!--                                <div class="uploaded_music">-->
									<!--                                    <v-container>-->
									<!--                                        <template>-->
									<!--                                            <div class="head_profile_content">-->
									<!--                                                <span class="_head_name">Releases</span>-->
									<!--                                            </div>-->

									<!--                                            <div class="wrap_music">-->
									<!--                                                <template v-if="releasesAlbumsLoader">-->
									<!--                                                    <albumGridSkeletonLoader v-for="index in 6" :key="index" :parent="'default_grid_albums'"></albumGridSkeletonLoader>-->
									<!--                                                </template>-->
									<!--                                                <template v-if="USER_UPLOADED_ALBUMS.length && !releasesAlbumsLoader">-->
									<!--                                                    <albumGrid-->
									<!--                                                        v-for="album in USER_UPLOADED_ALBUMS"-->
									<!--                                                        :key="album.id"-->
									<!--                                                        :album="album"-->
									<!--                                                        :small="true"-->
									<!--                                                        :playZone="USER_UPLOADED_ALBUMS">-->
									<!--                                                    </albumGrid>-->
									<!--                                                </template>-->
									<!--                                            </div>-->
									<!--                                            <template>-->
									<!--                                                <div class="nothing_albums" v-if="!releasesAlbumsLoader && !USER_UPLOADED_ALBUMS.length">-->
									<!--                                                    Nothing here yet<br> Go explore!-->
									<!--                                                </div>-->
									<!--                                            </template>-->
									<!--                                        </template>-->
									<!--                                    </v-container>-->
									<!--                                </div>-->


									<div class="recently_liked">
										<div class="container">
											<div class="head_profile_content">
												<h3 class="_head_name">Recently liked</h3>
												<template v-if="!recentlyLikedAlbumsLoader && USER_RECENTLY_LIKED_ALBUMS.length">
													<button type="button" class="show_more_link" @click="showComingSoonDialog = true">Library</button>
												</template>
											</div>
											<div class="wrap_music">
												<template v-if="recentlyLikedAlbumsLoader">
													<albumGridSkeletonLoader v-for="index in 6" :key="index"
													                         :parent="'default_grid_albums'"></albumGridSkeletonLoader>
												</template>
												<template v-if="!recentlyLikedAlbumsLoader && USER_RECENTLY_LIKED_ALBUMS.length">
													<albumGrid v-for="album in USER_RECENTLY_LIKED_ALBUMS.slice(0, 6)"
													           :key="album.id"
													           :album="album"
													           :playZone="USER_RECENTLY_LIKED_ALBUMS">
													</albumGrid>
												</template>
											</div>
											<template>
<!--												<div class="nothing_albums" v-if="!recentlyLikedAlbumsLoader && !USER_RECENTLY_LIKED_ALBUMS.length">-->
<!--													Nothing here yet<br> Go explore!-->
<!--												</div>-->
												<nothingHereYet v-if="!recentlyLikedAlbumsLoader && !USER_RECENTLY_LIKED_ALBUMS.length"></nothingHereYet>
											</template>
										</div>
									</div>
								</div>
							</v-tab-item>
							<v-tab-item :transition="false">
								<div class="events_section">
									<div class="uploaded_events">
										<v-container>
											<div class="head_profile_content">
	                                            <span class="_head_name">
	                                               Coming up
	                                            </span>
											</div>

											<nothingHereYet></nothingHereYet>
										</v-container>
									</div>
								</div>
							</v-tab-item>
							<v-tab-item :transition="false">
								<div class="about_section">
									<v-container class="v_container_info">
										<div class="head_profile_content">
                                    <span class="_head_name">
                                       Info
                                    </span>
										</div>
										<div class="wrap_about_section">
											<div class="artist_projects" v-if="USER_ARTISTS.length">
												<div class="label_">Projects:</div>
												<div class="list_artists">
													<div class="artists" v-for="artist in USER_ARTISTS" :key="artist.id">
														<div @click="redirectToArtistPage(artist.id, artist.identifier)">
															{{ artist.name.trim() }}
														</div>
														<span v-if="USER_ARTISTS.length > 1">,</span>
													</div>
												</div>
											</div>
											<!--                                        <div class="artist_owner" v-if="ARTIST_OWNER">-->
											<!--                                            Author-->
											<!--                                            <router-link :to="{name: 'user-profile', params: {id: ARTIST.ownerId}}">-->
											<!--                                                {{ ARTIST_OWNER.fullName }}-->
											<!--                                            </router-link>-->
											<!--                                        </div>-->
										</div>
									</v-container>

									<v-container>
										<div class="head_profile_content">
                                        <span class="_head_name">
                                          Bio
                                        </span>
										</div>
										<div class="wrap_about_section">
											<div class="_location" v-if="PROFILE_USER.address">
												{{ PROFILE_USER.address }}
											</div>
											<!--                                        <div class="_projects" v-if="USER_ARTISTS.length">-->
											<!--                                            Projects:-->
											<!--                                            <div class="artists" v-for="artist in USER_ARTISTS" :key="artist.id">-->
											<!--                                                <router-link :to="{name: 'artist-profile', params: {id: artist.id}}" target="_blank">-->
											<!--                                                    {{ artist.name.trim() }}-->
											<!--                                                </router-link>-->
											<!--                                                <span v-if="USER_ARTISTS.length > 1">,</span>-->
											<!--                                            </div>-->
											<!--                                        </div>-->
											<div class="_description" v-if="PROFILE_USER.description" v-html="formattedBio">
											</div>
											<template v-else>
												<nothingHereYet></nothingHereYet>
<!--												<div class="nothing_here_yet">-->
<!--													<div class="container">-->
<!--														Nothing here yet-->
<!--													</div>-->
<!--												</div>-->
											</template>
										</div>
									</v-container>
								</div>
							</v-tab-item>
						</v-tabs-items>
					</div>
				</div>
			</div>

			<ComingSoonDialog v-if="showComingSoonDialog" v-model="showComingSoonDialog"></ComingSoonDialog>
			<dialogNeedLogin v-if="dialogNeedLoginVisible" v-model="dialogNeedLoginVisible"></dialogNeedLogin>
			<errorDialog v-if="errorDialog" v-model="errorDialog"></errorDialog>

			<!------------------ Modal Image ------------------>
			<modalImage ref="modal-image" :image="originalImageUrl"/>

		</div>
	</userProfileLayout>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

import userProfileLayout from "@/layouts/profiles/userProfileLayout.vue";
import albumGrid from '@/components/musicReleases/albumGrid';
import headSkeletonLoader from "@/components/small/skeleton-loaders/headSkeletonLoader";
import nothingHereYet from "@/components/small/nothingHereYet";

const ComingSoonDialog = () => import("@/components/dialogs/comingSoonDialog");
const dialogNeedLogin = () => import('@/components/dialogs/needLogin');
const errorDialog = () => import('@/components/dialogs/error');

const ModalImage = () => import ("@/components/public/modalImage");
import isMobile from "@/mixins/isMobile";
import checkAccessToken from "@/mixins/checkAccessToken";
import albumGridSkeletonLoader from "@/components/small/skeleton-loaders/albumGridSkeletonLoader";
import tr from "vue2-datepicker/locale/es/tr";

export default {
	name: 'user-profile',
	props: ['id', 'name'],
	components: {
		userProfileLayout,
		albumGrid,
		ComingSoonDialog,
		dialogNeedLogin,
		errorDialog,
		ModalImage,
		headSkeletonLoader,
		albumGridSkeletonLoader,
		nothingHereYet
	},
	mixins: [checkAccessToken, isMobile],
	data() {
		return {
			apiUrl: process.env.VUE_APP_API_URL,
			originalImageUrl: '',
			smallImageUrl: '',
      smallImageUrlSrcset: '',
			isImageDefault: false,
			tabs: null,
			tabsSection: ['Music', 'Events', 'About'],
			defaultAva: '',
			selectSortByArtistAlbums: 'Release Date',
			sortByArtistAlbums: [
				'Release Date'
			],
			userID: '',
			shareLink: `${window.location.hostname}/user/${this.id}`,
			showComingSoonDialog: false,
			dialogNeedLoginVisible: false,
			errorDialog: false,
			loader: false,
			releasesAlbumsLoader: false,
			recentlyLikedAlbumsLoader: false,
			isFollow: false,
			hoverFollowingButton: false,
			actionButtonsLoader: false,
			followBtnLoader: false,
			messageBtnLoader: false,
			supportBtnLoader: false,
      cacheKey: new Date().getTime(),
      copiedUserLink: false,
		}
	},
	computed: {
		...mapGetters([
			'AUTH_DATA',
			'PROFILE_USER',
			'USER_UPLOADED_ALBUMS',
			'USER_RECENTLY_LIKED_ALBUMS',
			'USER_ARTISTS',
			'USER_FOLLOWING',
			'HAS_FOLLOWED_USER',
      'USER_LOGO',
      'PROFILE_USER_LOGO'
		]),
        formattedBio() {
            return this.PROFILE_USER.description.replace(/\n/g, '<br>');
        }
	},
	created() {

	},
	mounted() {
		this.getUserData();
	},
	methods: {
		...mapActions([
			// user profile info
			'GET_PROFILE_USER', 'GET_HAS_FOLLOWED_USER',

			// user albums
			'GET_USER_ARTISTS', 'GET_USER_UPLOADED_ALBUMS',

			// user recently liked albums
			'GET_USER_RECENTLY_LIKED_ALBUMS',

			// follow/unfollow
			'FOLLOW',
			'UNFOLLOW',
		]),
		redirectToArtistPage(artistId, artistName) {
			// const name = artistName.trim().replace(/ /g, '-');
			const routeData = this.$router.resolve({name: 'artist-profile', params: {id: artistId, name: window.toHumanFormat(artistName)}});
			window.open(routeData.href, '_blank');
		},

		getUserData() {
			this.loader = true;
			this.actionButtonsLoader = true;
			this.releasesAlbumsLoader = true;
			this.recentlyLikedAlbumsLoader = true;

			let user = this.id || this.name;

			this.GET_PROFILE_USER(user)
				.then(() => {
					if (!this.PROFILE_USER.id) {
						this.$router.push({name: "404"});
					}

          this.smallImageUrl = require('@/assets/image/svg/default_avatar.svg');
          if (this.PROFILE_USER_LOGO && this.PROFILE_USER_LOGO['240x270'] ) {
            this.smallImageUrl = this.PROFILE_USER_LOGO['240x270'];
            this.smallImageUrlSrcset = this.PROFILE_USER_LOGO['240x270@2x']
          } else {
            this.isImageDefault = true;
          }

          this.originalImageUrl = (this.PROFILE_USER_LOGO && this.PROFILE_USER_LOGO['960x1080']) ? this.PROFILE_USER_LOGO['960x1080'] :require('@/assets/image/svg/default_avatar.svg');


					if (this.AUTH_DATA.accessToken) {
						this.GET_HAS_FOLLOWED_USER(this.PROFILE_USER.id)
							.then(() => {
								this.isFollow = this.HAS_FOLLOWED_USER;
							})
							.catch(err => console.log(`GET_HAS_FOLLOWED_USER, ${err}`))
							.finally(() => {
								this.actionButtonsLoader = false;
							})
					} else {
						this.actionButtonsLoader = false;
					}
					this.getReleasesAlbums(this.PROFILE_USER.id);
					this.getRecentlyLikedAlbums(this.PROFILE_USER.id);

				})
				.catch(err => {
					console.error(`GET_PROFILE_USER, ${err}`);
					this.$router.push({name: "404"});
				})
				.finally(() => this.loader = false);
		},
		getReleasesAlbums(userID) {
			this.releasesAlbumsLoader = true;
			const params = {owner: userID};
			this.GET_USER_ARTISTS(params)
				.then(() => {
					if (this.USER_ARTISTS.length) {
						let userArtistsID = [];
						for (let i = 0; i < this.USER_ARTISTS.length; i++) {
							userArtistsID.push(this.USER_ARTISTS[i].id);
						}
						let params = {
							'artist': userArtistsID,
							'status': 'published'
						}
						this.GET_USER_UPLOADED_ALBUMS(params)
							.catch(err => console.log(`getReleasesAlbums, ${err}`))
							.finally(() => this.releasesAlbumsLoader = false);
					} else {
						this.releasesAlbumsLoader = false;
					}
				})
		},
		getRecentlyLikedAlbums(userID) {
			this.recentlyLikedAlbumsLoader = true;
			const params = {
				user: userID,
				status: 'published',
				limit: 6
			};
			this.GET_USER_RECENTLY_LIKED_ALBUMS(params)
				.catch(err => console.log(`getRecentlyLikedAlbums, ${err}`))
				.finally(() => this.recentlyLikedAlbumsLoader = false);
		},

		follow(userID) {
			if (this.AUTH_DATA.accessToken || !this.checkAccessToken()) {
				this.followBtnLoader = true;

				this.FOLLOW(userID)
					.then(result => {
						if (result === 'success') {
							this.followBtnLoader = false;
							this.isFollow = true;
							this.PROFILE_USER.followersCount += 1;
						} else {
							this.errorDialog = true;
						}
					})
					.catch(err => {
						this.errorDialog = true;
						console.error(`user follow, ${err}`)
					})
					.finally(() => this.followBtnLoader = false);
			} else {
				this.dialogNeedLoginVisible = true;
			}
		},
		unfollow(userID) {
			if (this.AUTH_DATA.accessToken || !this.checkAccessToken()) {
				this.followBtnLoader = true;

				this.UNFOLLOW(userID)
					.then(result => {
						if (result === 'success') {
							this.followBtnLoader = false;
							this.isFollow = false;
							if (this.PROFILE_USER.followersCount >= 0) {
								this.PROFILE_USER.followersCount -= 1;
							}
						} else {
							this.errorDialog = true;
						}
					})
					.catch(err => {
						this.errorDialog = true;
						console.error(`user unfollow, ${err}`)
					})
					.finally(() => this.followBtnLoader = false);
			} else {
				this.dialogNeedLoginVisible = true;
			}
		},

		copyUserLink() {
			navigator.clipboard.writeText(this.shareLink);

      this.copiedUserLink = true;

      setTimeout(() => {
        this.copiedUserLink = false;
      }, 2000);
		}
	}
}
</script>

<style scoped>
footer {
  margin-top: 200px !important;
}

</style>
